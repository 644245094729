<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {
    // if (this.getCookie("token") == "") {
    //   this.$router.push("/login");
    // }
  },
  beforeCreate() {},
  mounted() {
    // if (this.getCookie("token") == "") {
    //   this.$router.push("/login");
    // }
  },
  methods: {
    getCookie(c_name) {
      //判断document.cookie对象里面是否存有cookie
      if (document.cookie.length > 0) {
        var c_start = document.cookie.indexOf(c_name + "=");
        //如果document.cookie对象里面有cookie则查找是否有指定的cookie，如果有则返回指定的cookie值，如果没有则返回空字符串
        if (c_start != -1) {
          c_start = c_start + c_name.length + 1;
          var c_end = document.cookie.indexOf(";", c_start);
          if (c_end == -1) c_end = document.cookie.length;
          return unescape(document.cookie.substring(c_start, c_end));
        }
      }
      // return "";
    },
  },
};
</script>
<style lang="less">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-size: 16/96in;
}
#app {
  min-width: 1280/96in;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
  color: #000;
}
</style>
