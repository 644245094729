import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import axios from 'axios'
import qs from 'qs'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './unit/rem'
import './unit/network'
import Video from 'video.js'
import 'video.js/dist/video-js.css'
// import echarts from 'echarts'
import VideoPlayer from 'vue-video-player'

// Vue.prototype.$echarts = echarts
import htmlToPdf from './unit/htmlToPdf'
Vue.use(htmlToPdf)

Vue.prototype.$video = Video

Vue.use(VideoPlayer);

Vue.use(ElementUI);
Vue.prototype.$axios = axios
Vue.prototype.qs = qs
// Vue.prototype.$apiUrl = "http://192.168.1.210.8093"

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
