import axios from 'axios'
import Qs from 'qs'
import VueRouter from 'vue-router'
import Vue from 'vue'
Vue.use(VueRouter)
var router = new VueRouter()

const config = require("../../WebConfig");
// import { Dialog } from 'vant';

import { Message } from 'element-ui';
// var ua = navigator.userAgent.toLowerCase(); //获取判断用的对象
// let IsWX = false
// if (ua.match(/MicroMessenger/i) == "micromessenger") {
//     IsWX = true
// }
export function post(url, data, success, fail, complete) {
    data.token = getCookie('token');
   
    axios.post(config.ROOT + url, Qs.stringify(data), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        })
        .then (function(res){
            // console.log(this)
            if (res.data.code == 0) {
                Message.error(res.data.msg)
                fail && fail(res.data)
            } else if(res.data.code==-1){
                Message.error(res.data.msg)
                
                setTimeout(function(){
                    router.push('/login/wxlink')
                location.reload()
                },500)
            }else {
                success && success(res.data);
            }
            complete && complete();
        })
        .catch(function(err) {
            if (err.response.data.code == 401) {
                Message.error('登录失效，请重新登录')
                setTimeout(function(){
                    router.push('/login')
                location.reload()
                },500)
            }
            fail && fail(err);
            complete && complete();
        })
}

export function post_file(url, data, success, fail, complete) {
    data.append("token",  getCookie('token'));
    axios.post(config.ROOT + url, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
        .then (function(res){
            // console.log(this)
            if (res.data.code == 0) {
                Message.error(res.data.msg)
                // error && error(res.data)
            } else {
                success && success(res.data);
            }
            complete && complete();
        })
        .catch(function(err) {

            if (err.response) {
                //返回401跳转登录 否则无TOKEN 也可以访问
                if (err.response.data.code == 401) {
                    Message.error('登录失效，请重新登录')
                    setTimeout(function(){
                        router.push('/login')
                    location.reload()
                    },500)
                }
                // console.log(err.response)
            }
            fail && fail(err);
            complete && complete();
        })
}



export function get(url, data, success, fail, complete) {
    data.token = getCookie('token');
    axios.get(config.ROOT + url, { params: data })
        .then(function(res) {
            if (res.data.code == 0) {
                Message.error(res.data.msg)

            } else {
                success && success(res.data);
            }
            complete && complete();
        })
        .catch(function(err) {
            if (err.response) {
                //返回401跳转登录 否则无TOKEN 也可以访问
                if (err.response.data.code == 401) {
                    Message.error('登录失效，请重新登录')
                    setTimeout(function(){
                        router.push('/login')
                    location.reload()
                    },500)
                }
                // console.log(err.response)
            }
            fail && fail(err);
            complete && complete();
        })
}

function getCookie(c_name) {
    //判断document.cookie对象里面是否存有cookie
    if (document.cookie.length > 0) {
        var c_start = document.cookie.indexOf(c_name + "=")
            //如果document.cookie对象里面有cookie则查找是否有指定的cookie，如果有则返回指定的cookie值，如果没有则返回空字符串
        if (c_start != -1) {
            c_start = c_start + c_name.length + 1
            var c_end = document.cookie.indexOf(";", c_start)
            if (c_end == -1) c_end = document.cookie.length
            return unescape(document.cookie.substring(c_start, c_end))
        }
    }
    return ""
}