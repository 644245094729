import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/Main.vue'
// import Login from '../views/Login.vue'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
   VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
const routes = [
  { path: '/', name:'login2', component: () => import('../views/Login.vue') },
  { path: '/login',component: () => import('../views/Login.vue'),
    children: [
      { path: '/',name:'login1', component: () => import('../views/login/logins.vue') },// 登录
      { path: '/login/',name:'login', component: () => import('../views/login/logins.vue') },// 登录
      { path: '/login/logins',name: 'logins', component: () => import('../views/login/logins.vue') },
      { path: '/login/wxcode', name: 'wxcode',component: ()=>import('../views/login/wxCode.vue') },// 微信扫码登录
      { path: '/login/register',name: 'register', component: ()=>import('../views/login/register.vue')  },// 注册
      { path: '/login/resReg',name: 'resReg', component: ()=>import('../views/login/resReg.vue') }, // 注册成功
      { path: '/login/wxlink',name: 'wxlink', component: ()=>import('../views/login/wxlink.vue') }, // 微信扫码登录后绑定信息
      { path: '/login/find',name: 'find', component: ()=>import('../views/login/findpwd.vue') }, // 找回密码
      { path: '/login/repwd',name: 'repwd', component: ()=>import('../views/login/find2.vue') }, // 重置密码
      { path: '/login/findres',name: 'findres', component: ()=>import('../views/login/findres.vue') }, // 重置密码成功
      { path: '/login/finderr',name: 'finderr', component: ()=>import('../views/login/finderr.vue') }, // 重置密码失败
      { path: '/login/test',name: 'test', component: ()=>import('../views/login/test.vue') } // test
    ]
  },

  // { path: '/', name: 'Main' ,component: Main},
  {
    path: '/main',
    component: Main,
    children: [
      // { path: '/', component: ()=> import('../views/main/starts.vue') },
      { path: '/main/begins',name: 'begins', component: ()=> import('../views/main/begins.vue') }, // 备课
      { path: '/main/addclasses',name: 'addclasses', component: ()=> import('../views/main/addNewClass.vue') }, // 添加新课程
      { path: '/main/editclass',name: 'editclass', component: ()=> import('../views/main/editclass.vue') }, // 编辑课程
      { path: '/main/sectionadd',name: 'sectionadd', component: ()=> import('../views/main/sectionadd.vue') }, // 添加课时
      { path: '/main/addscene', name: 'addscene', component: ()=>import('../views/main/addScene.vue') }, // 添加场景/编辑场景
      { path: '/main/addscene/pptresource', name: 'pptresource', component: ()=>import('../views/main/pptResource.vue'),children:[
        { path: '/main/addscene/pptresource/pptlocal', name: 'pptlocal', component: ()=>import('../views/main/pptlocal.vue') }, // 添加ppt资源,本地上传
        { path: '/main/addscene/pptresource/pptpersonal', name: 'pptpersonal ', component: ()=>import('../views/main/pptPersonal.vue') }, // 添加ppt资源,个人资源导入
        { path: '/main/addscene/pptresource/pptsystem', name: 'pptsystem ', component: ()=>import('../views/main/pptSystem.vue') }, // 添加ppt资源,个人资源导入
      ] }, // 添加ppt资源

      { path: '/main/addscene/pictureresource',name: 'pictureresource', component: ()=>import('../views/main/pictureResource.vue') ,children:[
        { path: '/main/addscene/pictureresource/',nmae: 'picturelocal', component: ()=>import('../views/main/picturelocal.vue') },
        { path: '/main/addscene/pictureresource/picturelocal',nmae: 'picturelocals', component: ()=>import('../views/main/picturelocal.vue') },
      ]}, // 图文
      
      // { path: '/main/addscene/videoresource',name: 'videoresource', component: ()=>import('../views/main/videoResource.vue') ,children:[
      //   { path: '/main/addscene/videoresource/',nmae: 'videolocal', component: ()=>import('../views/main/videolocal.vue') },
      //   { path: '/main/addscene/videoresource/videolocal',nmae: 'videolocals', component: ()=>import('../views/main/videolocal.vue') },
      // ]}, // 视频

      { path: '/main/addscene/topicresource', name: 'topicrecource',component: ()=>import('../views/main/topicresource.vue') ,children:[
        { path: '/main/addscene/topicresource/topiclocal',name :'topiclocal', component: ()=>import('../views/main/topiclocal.vue') ,children:[
          { path: '/main/addscene/topicresource/topiclocal/', name: 'topiclocal1s', component: ()=>import('../views/main/topiclocal1.vue') },
          { path: '/main/addscene/topicresource/topiclocal/topiclocal1', name: 'topiclocal1', component: ()=>import('../views/main/topiclocal1.vue') },
          { path: '/main/addscene/topicresource/topiclocal/topiclocal2', name: 'topiclocal2', component: ()=>import('../views/main/topiclocal2.vue') }, //手动添加试题
          { path: '/main/addscene/topicresource/topiclocal/topiclocal3', name: 'topiclocal3', component: ()=>import('../views/main/topiclocal3.vue') }, //快捷导入试题
        ]} ,// 添加试题资源，本地上传
        { path: '/main/addscene/topicresource/topicpersonal',name: 'topicPersonal', component: ()=>import('../views/main/topicPersonal.vue') }, //个人资源
        { path: '/main/addscene/topicresource/topicsystem',name: 'topicsystem', component: ()=>import('../views/main/topicsystem.vue') }, // 系统资源
      ]}, // 添加试题资源

      

      { path: '/main/starts', name: 'starts', component: ()=>import('../views/main/starts.vue') }, // 上课
      { path: '/main/classes', name: 'classes', component: ()=>import('../views/main/classes.vue') }, // 班级
      { path: '/main/record', name: 'record', component: ()=>import('../views/main/record.vue'),children:[
        { path: '/main/record/' ,name: 'datas', component: ()=>import('../components/datas/datas1.vue') },
        { path: '/main/record/datas1' ,name: 'datas1', component: ()=>import('../components/datas/datas1.vue') },
        { path: '/main/record/answer' ,name: 'answer', component: ()=>import('../components/datas/answer.vue') }, // 答案详情
        { path: '/main/record/question' ,name: 'question', component: ()=>import('../components/datas/question.vue') }, // 问卷详情
        { path: '/main/record/classes' ,name: 'recordclasses', component: ()=>import('../components/datas/classes.vue') }, // 班级详情
        { path: '/main/record/stuanswer' ,name: 'stuanswer', component: ()=>import('../components/datas/stuanswer.vue') }, // 学生答案详情
        { path: '/main/record/qstu' ,name: 'qstu', component: ()=>import('../components/datas/qstu.vue') }, // 学生问卷详情
        { path: '/main/record/student' ,name: 'student', component: ()=>import('../components/datas/student.vue') }, // 学生详情
      ] }, // 数据

    ]
  },
  
  { path: '/hallindex', component: ()=>import('../components/attend/Hallindex.vue') },
  { path: '/hall',nmae: 'hall', component: ()=>import('../components/attend/Hall.vue') ,children:[
    // { path: '/hall/', component: ()=>import('../components/attend/Hallppt.vue') },
    { path: '/hall/ppt',name: 'hallppt', component: ()=>import('../components/attend/Hallppt.vue') },
    { path: '/hall/video',name: 'hallvideo', component: ()=>import('../components/attend/Hallvideo.vue') },
    { path: '/hall/real',name: 'real', component: ()=>import('../components/attend/real.vue') },
    { path: '/hall/topic',name: 'halltopic', component: ()=>import('../components/attend/Halltopic.vue') },
    { path: '/hall/picture',name: 'hallpicture', component: ()=>import('../components/attend/Hallpicture.vue') },
    { path: '/hall/music',name: 'hallmusic', component: ()=>import('../components/attend/Hallmusic.vue') },
    { path: '/hall/artical',name: 'hallartical', component: ()=>import('../components/attend/Hallartical.vue') },
    { path: '/hall/three',name: 'hallreal', component: ()=>import('../components/attend/Hallreal.vue') },
    { path: '/hall/question',name: 'hallquestion', component: ()=>import('../components/attend/Hallquestion.vue') },
  ]},
  { path: '/help', component: ()=>import('../components/help.vue') },
  { path: '/set', component: ()=>import('../components/set.vue') }
 
]

const router = new VueRouter({
  routes
})

export default router
