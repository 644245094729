<template>
  <div class="main">
    <!-- <h1 @click="toLogin">登录</h1> -->
    <header>
      <div style="display: flex">
        <img src="../img/板块图标 - 副本_slices/logo.png" alt="" />
      </div>
      <!-- 返回，设置。。。 -->
      <ul class="fun">
        <li @click="backto" v-show="isback">
          <img src="../img/上课/返回.png" alt="" />
          <span>返回</span>
        </li>
        <li>
          <!-- <span>帮助</span> -->
          <el-dropdown
            trigger="click"
            placement="bottom-start"
            style="height: 50/96in; display: flex"
          >
            <div
              class="el-dropdown-link"
              style="display: flex; align-items: center; height: 30px"
            >
              <img src="../img/上课/帮助 (5).png" alt="" />
              帮助
            </div>
            <el-dropdown-menu slot="dropdown">
              <!-- <el-dropdown-item>
                <span @click="tohelp">系统通知</span>
              </el-dropdown-item> -->
              <el-dropdown-item>
                <span @click="tohelp2">系统介绍</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>
        <li @click="toset">
          <img src="../img/上课/设置 (6).png" alt="" />
          <span>设置</span>
        </li>
        <li @click="out">
          <img src="../img/上课/退出 (5).png" alt="" />
          <span>退出</span>
        </li>
      </ul>
    </header>
    <main>
      <!-- 功能 上课 备课 班级 数据 -->
      <ul class="functions">
        <li
          @click="
            num = 0;
            classStart();
          "
          :class="{ blue: num == 0 }"
        >
          <div class="img" :class="{ active: num == 0 }">
            <img
              v-show="num == 0"
              src="../img/板块图标 - 副本_slices/课程 (6) 拷贝.png"
              alt=""
              style="width: 26/96in"
            />
            <img
              v-show="num != 0"
              src="../img/板块图标 - 副本_slices/课程 (6) 拷贝 2.png"
              alt=""
              style="width: 26/96in"
            />
          </div>
          <span>上课</span>
        </li>
        <li
          @click="
            num = 1;
            classBegins();
          "
          :class="{ blue: num == 1 }"
        >
          <div class="img" :class="{ active: num == 1 }">
            <img
              v-show="num != 1"
              src="../img/板块图标 - 副本_slices/课程 (4) 拷贝.png"
              alt=""
              style="width: 26/96in"
            />
            <img
              v-show="num == 1"
              src="../img/板块图标 - 副本_slices/课程 (4) 拷贝 2.png"
              alt=""
              style="width: 26/96in"
            />
          </div>
          <span>备课</span>
        </li>
        <li
          @click="
            num = 2;
            classes();
          "
          :class="{ blue: num == 2 }"
        >
          <div class="img" :class="{ active: num == 2 }">
            <img
              v-show="num != 2"
              src="../img/板块图标 - 副本_slices/班级 (1) 拷贝.png"
              alt=""
              style="width: 26/96in"
            />
            <img
              v-show="num == 2"
              src="../img/板块图标 - 副本_slices/班级 (1) 拷贝 2.png"
              alt=""
              style="width: 26/96in"
            />
          </div>
          <span>班级</span>
        </li>
        <li
          @click="
            num = 3;
            record();
          "
          :class="{ blue: num == 3 }"
        >
          <div class="img" :class="{ active: num == 3 }">
            <img
              v-show="num != 3"
              src="../img/板块图标 - 副本_slices/数据 (1) 拷贝.png"
              alt=""
              style="width: 26/96in"
            />
            <img
              v-show="num == 3"
              src="../img/板块图标 - 副本_slices/数据 (1) 拷贝 2.png"
              alt=""
              style="width: 26/96in"
            />
          </div>
          <span>数据</span>
        </li>
      </ul>
      <div class="mains">
        <router-view></router-view>
      </div>
    </main>
  </div>
</template>
<script>
// import * as util from "@/unit/network";
export default {
  name: "Main",
  data() {
    return {
      num: 0,
      info: 0,
      isback: false,
    };
  },
  mounted() {
    // this.getMain();
    // if (this.num == 0) {
    //   this.classStart();
    // }
    // console.log(this.$route.path);
    // let str = this.$route.path;
    // console.log((str = str.substring(0, 12)));
    // this.num = this.info;
    if (this.$route.path == "/main") {
      this.classStart();
    }
    if (this.$route.path.substring(0, 12) == "/main/starts") {
      this.num = 0;
    } else if (this.$route.path.substring(0, 5) == "/hall") {
      this.num = 0;
    } else if (
      this.$route.path.substring(0, 12) == "/main/begins" ||
      this.$route.path.substring(0, 14) == "/main/addscene" ||
      this.$route.path.substring(0, 16) == "/main/addclasses"
    ) {
      this.num = 1;
    } else if (this.$route.path.substring(0, 13) == "/main/classes") {
      this.num = 2;
    } else if (this.$route.path.substring(0, 12) == "/main/record") {
      this.num = 3;
    }
    console.log(this.$route.query.num);
  },
  methods: {
    // toLogin() {
    //   this.$router.push("/login");
    // },
    tohelp() {
      this.$router.push({
        path: "/help",
        query: {
          id: 1,
        },
      });
    },
    tohelp2() {
      this.$router.push({
        path: "/help",
        query: {
          id: 2,
        },
      });
    },
    toset() {
      this.$router.push("/set");
    },
    // 退出
    out() {
      this.setCookie("token", "", -1);
      this.$router.push("/login");
    },
    // getMain() {
    //   // util.post("/login", { phone: this.user, password: this.pwd });
    //   this.aa = this.$route.path;
    // },
    // 上课
    classStart() {
      this.$router.push("/main/starts");
      // this.getMain();
    },
    // 备课
    classBegins() {
      this.$router.push("/main/begins");
      // this.getMain();
    },
    // 班级
    classes() {
      this.$router.push({
        path: "/main/classes",
      });
      // this.getMain();
    },
    // 数据
    record() {
      this.$router.push({
        path: "/main/record",
      });
      // this.getMain();
    },
    setCookie(name, value, type) {
      var Days = 1;
      var exp = new Date();
      exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
      var x;
      if (type) {
        x = new Date(
          new Date(new Date().toLocaleDateString()).getTime() +
            24 * 60 * 60 * 1000 -
            1
        );
      } else {
        x = exp.toGMTString();
      }
      document.cookie =
        name + "=" + escape(value) + ";expires=" + x + ";path=/";
    },
    backto() {
      if (
        this.$route.path == "/main/addscene/pptresource/pptlocal" ||
        this.$route.path == "/main/addscene/topicresource/topiclocal" ||
        this.$route.path == "/main/addscene/pictureresource/picturelocal"
      ) {
        this.$router.push({
          path: "/main/addscene",
          query: {
            csid: this.$route.query.csid,
          },
        });
      } else {
        this.$router.back(-1);
      }
      console.log(
        this.$route.path == "/main/addscene/pptresource/pptlocal" ||
          this.$route.path == "/main/addscene/pictureresource/picturelocal"
      );
    },
  },
  watch: {
    $route(now, old) {
      //监控路由变换，控制返回按钮的显示
      console.log(now.path, old);
      if (
        now.path == "/main/begins" ||
        now.path == "/main/starts" ||
        now.path == "/main/classes" ||
        now.path == "/main/record"
      ) {
        this.isback = false;
      } else {
        this.isback = true;
      }
      if (now.path == "/main/addclasses") {
        this.num = 1;
      }
    },
    // num: {
    //   handler(newVal, oldVal) {
    //     console.log(newVal, oldVal);
    // if (
    //   this.$route.path.substring(0, 12) == "/main/starts" &&
    //   this.num == 0
    // ) {
    //   this.num = 0;
    //   console.log(111);
    // } else if (this.$route.path.substring(0, 5) == "/hall") {
    //   this.num = 0;
    // } else if (
    //   this.$route.path.substring(0, 12) == "/main/begins" ||
    //   this.$route.path.substring(0, 14) == "/main/addscene"
    // ) {
    //   this.num = 1;
    // } else if (this.$route.path.substring(0, 13) == "/main/classes") {
    //   this.num = 2;
    // } else if (this.$route.path.substring(0, 12) == "/main/record") {
    //   this.num = 3;
    // }
    //   },
    //   immediate: true,
    // },
  },
  components: {},
};
</script>
<style lang="less" scoped>
header {
  height: 60/96in;
  background-color: #e8e8e8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.35rem /* 35/100 */ 0 0.2rem /* 56/100 */;
  border-bottom: 1px solid #cdcdcd;
  > div {
    img {
      height: 36/96in;
    }
  }
  .fun {
    display: flex;
    font-size: 14/96in;
    li {
      margin-left: 0.33rem /* 33/100 */;
      cursor: pointer;
      display: flex;
      align-items: center;
      img {
        height: 22/96in;
        margin-right: 8px;
      }
    }
  }
}
main {
  display: flex;

  .functions {
    width: 80/96in;
    padding-top: 20/96in;
    background: #e5e7ea;
    li {
      // text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 39/96in;
      cursor: pointer;
      color: #4e4e4e;
      .img {
        width: 52/96in;
        height: 52/96in;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f3f3f3;
        border-radius: 0.12rem /* 12/100 */;
      }
      span {
        font-size: 12/96in;
        font-weight: 400;

        margin-top: 14/96in;
      }
    }
  }
  .mains {
    width: 100%;
    // min-height: 1020/96in;
    height: calc(100vh - 0.625in);
    // min-height: calc(100% - 60 / 96in);
    background-color: #eff0f2;
  }
}
.active {
  border: 2.4/96in solid #1c5eff;
  background: #e8efff !important;
}
.blue {
  color: #1c5eff !important;
}
</style>
