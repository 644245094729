//项目域名地址
// let url = 'http://192.168.1.210:8093';
// let url = "http://192.168.1.162:8090/api";
let url = "http://zy.yunlearning.com:8083/api";
// let uploadUrl = "http://192.168.1.162:8090/upload";
// 线上
let ROOT ;
// let ROOT1 ;
//由于封装的axios请求中，会将ROOT打包进去，为了方便之后不再更改，判断了当前环境，而生成的不同的ROOT
if (process.env.NODE_ENV === 'development') {
    //开发环境下的代理地址，解决本地跨域跨域，配置在config目录下的index.js dev.proxyTable中
    // url = "http://192.168.1.210:8093";
    // url = "http://192.168.1.162:8090";
    url = "http://zy.yunlearning.com:8083/";
    ROOT = "/api";
    // ROOT1 = "/upload"


} else {
    //生产环境下的地址
    ROOT = url;
    // ROOT1 = uploadUrl
}
module.exports.PROXYROOT = url; //代理指向地址
// module.exports.PROXYROOT1 = uploadUrl; //代理指向地址
module.exports.ROOT = ROOT;
// module.exports.ROOT1 = ROOT1;

//为了防止 冲突
module.exports = {
    PROXYROOT: url, //代理指向地址
    // PROXYROOT1: uploadUrl, //代理指向地址
    ROOT: ROOT,
    // ROOT1: ROOT1
}